import React from "react"
import { CmsContentBaseProps } from "../../types"
import Banner from "@components/Banner"
import { urlFor } from "@utils/sanity"

interface ComponentBannerProps extends CmsContentBaseProps {}

const ComponentBanner: React.FC<ComponentBannerProps> = ({
  title,
  subtitle,
  backgroundImage,
  mobileBackgroundImage,
  cta,
}) => {
  return (
    <Banner
      title={title}
      subtitle={subtitle}
      image={urlFor(backgroundImage?.asset).url() || ""}
      mobileImage={
        urlFor(mobileBackgroundImage?.asset).url() ||
        urlFor(backgroundImage?.asset).url() ||
        ""
      }
      buttonText={cta.label}
      buttonLink={cta.internalLink || cta.externalLink}
      isExternal={!!cta.externalLink}
      // TODO: handle CTA
    />
  )
}

export default ComponentBanner
