import React from "react"
import { AspectRatio } from "@chakra-ui/react"

export interface VideoProps {
  videoURL?: string
}

export const Video: React.FC<VideoProps> = ({ videoURL }) => {
  return (
    <>
      {videoURL && (
        <AspectRatio ratio={16 / 9}>
          <iframe
            src={videoURL}
            allow="autoplay; encrypted-media"
            allowFullScreen
            frameBorder="0"
            title="video"
          />
        </AspectRatio>
      )}
    </>
  )
}
