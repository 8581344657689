import React from "react"
import { CmsContentBaseProps } from "../../types"
import BannerCarousel from "@components/BannerCarousel"
import { urlFor } from "@utils/sanity"

interface ComponentBannerCarouselProps extends CmsContentBaseProps {}

const ComponentBannerCarousel: React.FC<ComponentBannerCarouselProps> = ({
  position,
  banners,
}) => {
  const formattedBanners = banners.map((b) => {
    return {
      title: b.title,
      subtitle: b.subtitle,
      image: urlFor(b.backgroundImage?.asset).url() || "",
      mobileImage:
        urlFor(b.mobileBackgroundImage?.asset).url() ||
        urlFor(b.backgroundImage?.asset).url() ||
        "",
      buttonText: b.cta.label,
      buttonLink: b.cta.internalLink || b.cta.externalLink,
      isExternal: !!b.cta.externalLink,
    }
  })

  return <BannerCarousel data={formattedBanners} />
}

export default ComponentBannerCarousel
