import React from "react"
import { CmsContentBaseProps } from "../../types"
import VerticalDotSpacer from "@components/VerticalDotSpacer"

interface ComponentVerticalDotSpacerProps extends CmsContentBaseProps {}

const ComponentVerticalDotSpacer: React.FC<ComponentVerticalDotSpacerProps> = ({
  dots,
}) => {
  return <VerticalDotSpacer dots={dots} />
}

export default ComponentVerticalDotSpacer
