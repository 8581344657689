import React from "react"
import { CmsContentBaseProps } from "../../types"
import LegalContent from "@components/LegalContent"

interface ComponentLegalContentProps extends CmsContentBaseProps {}

const ComponentLegalContent: React.FC<ComponentLegalContentProps> = ({
  title,
  content,
}) => {
  return <LegalContent title={title} content={content} />
}

export default ComponentLegalContent
