import React from "react"
import { CmsContentBaseProps } from "../../types"
import Downloads from "@components/Downloads"

interface ComponentDownloadsProps extends CmsContentBaseProps {}

const ComponentDownloads: React.FC<ComponentDownloadsProps> = ({
  header,
  downloads,
}) => {
  return (
    <Downloads
      header={header}
      downloads={downloads.map((d) => {
        const fileSegments = d.download.split(".")
        const fileType = fileSegments[fileSegments.length - 1] || ""
        return {
          title: d.label,
          image: d.image,
          mobileImage: d.mobileImage,
          download: d.download,
          metaData: fileType.trim(),
        }
      })}
    />
  )
}

export default ComponentDownloads
