import React from "react"
import { CmsContentBaseProps } from "../../types"
import Faq from "@components/Faq"

interface ComponentFeatureBlockProps extends CmsContentBaseProps {}

const ComponentFeatureBlock: React.FC<ComponentFeatureBlockProps> = ({
  title,
  questionsAndAnswers,
}) => {
  return <Faq title={title} questionsAndAnswers={questionsAndAnswers} />
}

export default ComponentFeatureBlock
