import React from "react"
import { Flex, FlexProps } from "@chakra-ui/react"
import { ArrowRight, ArrowLeft } from "@components/universal/icons"
import { bp } from "@utils/MediaQueries"

export interface IArrowsProps extends FlexProps {
  containerRef: React.RefObject<HTMLDivElement>
}

const Arrows: React.FC<IArrowsProps> = ({ containerRef }) => {
  const [shouldHide, setShouldHide] = React.useState(false)
  const [shouldHideLeftArrow, setShouldHideLeftArrow] = React.useState(false)
  const [shouldHideRightArrow, setShouldHideRightArrow] = React.useState(false)

  const slideContainer = (dir: string) => {
    const numberOfCards = 3 * (dir === "right" ? -1 : 1)
    containerRef.current?.scrollBy({
      behavior: "smooth",
      left: (containerRef.current.children[0].clientWidth + 16) * numberOfCards,
    })
  }

  const widthListener = React.useCallback(() => {
    const container = containerRef.current
    if (container) {
      const maxScroll = container.scrollWidth - container.clientWidth
      const isFullLeft = container.scrollLeft === 0
      const isFullRight = container.scrollLeft === maxScroll
      const hasOverflow = container.scrollWidth > container.clientWidth
      if (!hasOverflow) {
        setShouldHide(true)
      } else {
        setShouldHide(false)
      }
      isFullLeft ? setShouldHideLeftArrow(true) : setShouldHideLeftArrow(false)
      isFullRight
        ? setShouldHideRightArrow(true)
        : setShouldHideRightArrow(false)
    }
  }, [setShouldHide, containerRef.current])

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", widthListener)
      window.addEventListener("resize", widthListener)
      widthListener()
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", widthListener)
        window.removeEventListener("resize", widthListener)
      }
    }
  }, [widthListener])

  return (
    <>
      <Flex
        hidden={shouldHide || shouldHideLeftArrow}
        position="absolute"
        left="0"
        top="108px"
        alignItems="center"
        justifyContent="center"
        display={bp("none", null, "flex")}
        h="calc((4.5 / 16) * 100vw)"
        w="calc((1 / 16) * 100vw)"
        bgColor="rgba(255, 255, 255, 0.5)"
        cursor="pointer"
        onClick={() => slideContainer("right")}
      >
        <ArrowLeft stroke="#63666A" />
      </Flex>
      <Flex
        hidden={shouldHide || shouldHideRightArrow}
        position="absolute"
        right="0"
        top="108px"
        alignItems="center"
        justifyContent="center"
        display={bp("none", null, "flex")}
        h="calc((4.5 / 16) * 100vw)"
        w="calc((1.5 / 16) * 100vw - 48px)"
        bgColor="rgba(255, 255, 255, 0.5)"
        cursor="pointer"
        onClick={() => slideContainer("left")}
      >
        <ArrowRight stroke="#63666A" />
      </Flex>
    </>
  )
}

export default Arrows
