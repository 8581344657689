import React from "react"
import { CmsContentBaseProps } from "../../types"
import VerticalSpace from "@components/VerticalSpace"

interface ComponentVerticalSpaceProps extends CmsContentBaseProps {}

const ComponentVerticalSpace: React.FC<ComponentVerticalSpaceProps> = ({
  height,
  mobileHeight,
}) => {
  return <VerticalSpace height={height} mobileHeight={mobileHeight} />
}

export default ComponentVerticalSpace
