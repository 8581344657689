import React from "react"
import colors from "@constants/colors"
import { Box, Flex, Text, css, useTheme } from "@chakra-ui/react"
import Image from "next/image"
import Link from "@components/Link"
import { bp, LaptopUpOnly, MobileOnly } from "@utils/MediaQueries"
import Spacer from "@components/universal/Spacer"

export interface BannerProps {
  title: string
  subtitle: string
  image: string
  mobileImage?: string
  buttonText: string
  buttonLink: string
  isExternal?: boolean
}

const Banner: React.FC<BannerProps> = ({
  title,
  subtitle,
  image,
  mobileImage,
  buttonText,
  buttonLink,
  isExternal,
}) => {
  const theme = useTheme()
  const imageContainerStyles = css({
    span: {
      position: "relative !important",
      height: "100% !important",
    },
    img: {
      position: "relative !important",
      width: "100% !important",
      height: "100% !important",
      paddingLeft: bp("0 !important", null, "0 !important"),
      paddingRight: bp("0 !important", null, "0 !important"),
    },
  })(theme)

  return (
    <Flex
      position="relative"
      flexDirection={bp("column", null, "row-reverse")}
      flexShrink={0}
      alignItems="center"
      justifyContent={bp("center", null, "center")}
      w="100%"
      p={bp("0px 40px", null, "0 1col", "0 1p5col")}
      fontFamily="Avenir"
      fontWeight="800"
      bgColor={colors.light}
    >
      <Box
        h={bp(null, null, "100%", "100%")}
        w={bp("initial", null, "50%")}
        mb={bp("27px", null, "100px")}
        p={bp("20px", null, "64px")}
      >
        <MobileOnly p="20px" css={imageContainerStyles}>
          <Image
            src={image}
            alt="Banner Image"
            quality={80}
            loading="eager"
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </MobileOnly>
        <LaptopUpOnly css={imageContainerStyles}>
          <Image
            src={mobileImage || image}
            alt={title || "Banner Image"}
            quality={80}
            loading="eager"
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </LaptopUpOnly>
      </Box>
      <Box
        flexDir="column"
        flexShrink={0}
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
        w={bp("100%", null, "6col")}
        h={bp("initial", null, "100%")}
        textAlign={bp("center", null, "start")}
      >
        <Text
          as="header"
          fontSize={bp("33px", null, "40px", "69px")}
          lineHeight={bp("40px", null, "48px", "80px")}
          width="100%"
        >
          {title}
        </Text>
        <Text
          width="100%"
          m={bp("20px 0", null, "10px 0 40px")}
          fontSize={bp("16px", null, null, "19px")}
        >
          {subtitle}
        </Text>
        <Link
          zIndex={1}
          external={isExternal}
          href={buttonLink}
          color="white"
          _hover={{ textDecor: "none" }}
          alignSelf={bp("center", null, "flex-start")}
          justifyContent="center"
        >
          <Box
            alignItems="center"
            as="button"
            _hover={{ bg: colors.blackButtonFocus }}
            display="flex"
            h="58px"
            p="0 32px"
            borderRadius="6px"
            bg="#000"
            fontSize={bp("13.5px", null, "16px")}
            fontWeight="800"
            color="#FFF"
            _focus={{ boxShadow: "outline", outline: "none" }}
            zIndex={3}
          >
            {buttonText}
          </Box>
        </Link>
        <Spacer height="20px" />
      </Box>
    </Flex>
  )
}

export default Banner
