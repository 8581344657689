import React from "react"
import { CmsContentBaseProps } from "../../types"
import Spacer from "@components/universal/Spacer"
import Colors from "@constants/colors"

interface ComponentSpacerProps extends CmsContentBaseProps {}

const ComponentSpacer: React.FC<ComponentSpacerProps> = ({ size }) => {
  return (
    <Spacer
      height={size}
      width="100%"
      horizontal={true}
      backgroundColor={Colors.midLight}
    />
  )
}

export default ComponentSpacer
