import React from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import ProductCard, { ProductProps } from "@components/ProductCard"
import Arrows from "./Arrows"
import { LaptopUpOnly, MobileOnly } from "@utils/MediaQueries"
import Link from "@components/Link"

export interface ProductSliderProps {
  header: string
  products: ProductProps[]
  cta: {
    externalLink: string
    internalLink: string
    label: string
  }
  placeholderImage: string
}

const Container = styled.div({
  position: "relative",
  flexWrap: "nowrap",
  alignSelf: "flex-end",
  display: "flex",
  overflowX: "auto",
  WebkitOverflowScrolling: "touch",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
  width: "100%",
})

const ProductSlider: React.FC<ProductSliderProps> = ({
  header,
  products,
  cta,
  placeholderImage,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const ctaLink = cta?.externalLink || cta?.internalLink

  return (
    <>
      <LaptopUpOnly>
        <Flex
          position="relative"
          direction="column"
          overflow="hidden"
          paddingLeft="calc((1 / 16) * 100vw)"
        >
          <Flex
            justifyContent="space-between"
            width="100%"
            margin="0 0 32px"
            paddingRight="calc((1 / 16) * 100vw)"
          >
            <Text px="md" fontFamily="Avenir" fontSize="40px" fontWeight="800">
              {header}
            </Text>
            {ctaLink && cta.label && (
              <Link href={ctaLink} color="white" _hover={{ textDecor: "none" }}>
                <Box
                  as="button"
                  py={3}
                  px={8}
                  bg="black"
                  rounded="lg"
                  fontWeight="800"
                  fontFamily="Avenir"
                  fontSize="16px"
                  color="white"
                  _focus={{ boxShadow: "outline", outline: "none" }}
                >
                  {cta.label}
                </Box>
              </Link>
            )}
          </Flex>
          <Container ref={containerRef}>
            {products.map((card, idx) => (
              <ProductCard
                placeholderImage={placeholderImage}
                product={card}
                key={idx}
              />
            ))}
          </Container>
          <Arrows containerRef={containerRef} />
        </Flex>
      </LaptopUpOnly>
      <MobileOnly>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          padding="0 20px 22px"
        >
          <Text
            width={cta && cta.label && ctaLink ? "calc(50% - 20px)" : "100%"}
            fontFamily="Avenir"
            fontSize="23px"
            fontWeight="800"
          >
            {header}
          </Text>
          {cta && ctaLink && cta.label && (
            <Link href={ctaLink} color="white" _hover={{ textDecor: "none" }}>
              <Box
                as="button"
                height="3.5rem"
                py=".5rem"
                px="2rem"
                bg="black"
                rounded="lg"
                fontWeight="800"
                fontFamily="Avenir"
                fontSize="13.5px"
                color="white"
                _focus={{ boxShadow: "outline", outline: "none" }}
              >
                {cta.label}
              </Box>
            </Link>
          )}
        </Flex>
        <Container>
          {products.map((card, idx) => (
            <ProductCard
              placeholderImage={placeholderImage}
              product={card}
              key={idx}
            />
          ))}
        </Container>
      </MobileOnly>
    </>
  )
}

export default ProductSlider
