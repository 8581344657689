import React from "react"
import styled from "@emotion/styled"
import { Box, BoxProps, Flex } from "@chakra-ui/react"
import { ArrowLeft, ArrowRight } from "@components/universal/icons"
import Button from "@components/universal/Button"
import Hero, { HeroProps } from "@components/Hero"
import { bp } from "@utils/MediaQueries"
import { throttle } from "lodash"

export interface HeroCarouselProps
  extends Omit<BoxProps, "title" | "children"> {
  autoplayTimer?: string
  data: HeroProps[]
  children?: React.ReactNode
}

const Container = styled.div({
  position: "relative",
  flexWrap: "nowrap",
  display: "flex",
  overflowX: "auto",
  WebkitOverflowScrolling: "touch",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
})

const LEFT = "LEFT"
const RIGHT = "RIGHT"

const HeroCarousel: React.FC<HeroCarouselProps> = ({
  autoplayTimer,
  data,
  children,
  ...rest
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [carouselIdx, setCarouselIdx] = React.useState(0)

  const timeoutRef = React.useRef<any>(undefined)
  const useAutoplay = autoplayTimer && data.length > 1

  const scrollCarousel = (myRef: any, direction: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }

    const currentWidth = myRef.current.offsetWidth
    let currentIdx = carouselIdx

    if (direction === RIGHT) {
      if (carouselIdx === data.length - 1) {
        myRef.current.scrollTo({
          left: 0,
          behavior: "smooth",
        })
        setCarouselIdx(0)
      } else {
        myRef.current.scrollTo({
          left: myRef.current.scrollLeft + currentWidth,
          behavior: "smooth",
        })
        carouselIdx !== data.length - 1 && setCarouselIdx((currentIdx += 1))
      }
    } else if (direction === LEFT) {
      myRef.current.scrollTo({
        left: myRef.current.scrollLeft - currentWidth,
        behavior: "smooth",
      })
      carouselIdx !== 0 && setCarouselIdx((currentIdx -= 1))
    }
  }

  const onResize = () => {
    if (containerRef?.current && data?.length > 1) {
      const updatedScrollLeft =
        carouselIdx * containerRef.current.getBoundingClientRect().width
      containerRef.current.scrollLeft = updatedScrollLeft
    }
  }

  const onResizeThrottled = throttle(onResize, 100)

  React.useEffect(() => {
    window.addEventListener("resize", onResizeThrottled)

    return () => {
      window.removeEventListener("resize", onResizeThrottled)
    }
  }, [carouselIdx])

  const handleOnOpenVideo = () => {
    if (useAutoplay && timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
  }

  const handleOnCloseVideo = () => {
    if (useAutoplay) {
      timeoutRef.current = setTimeout(() => {
        scrollCarousel(containerRef, RIGHT)
      }, parseInt(autoplayTimer) * 1000)
    }
  }

  React.useEffect(() => {
    if (useAutoplay) {
      timeoutRef.current = setTimeout(() => {
        scrollCarousel(containerRef, RIGHT)
      }, parseInt(autoplayTimer) * 1000)
    }
  }, [carouselIdx])

  return (
    <Box position="relative" {...rest}>
      <Container ref={containerRef}>
        {data?.map((item, idx) => (
          <Hero
            handleOnOpen={handleOnOpenVideo}
            handleOnClose={handleOnCloseVideo}
            loadMode={0 === idx ? "eager" : "lazy"}
            key={idx}
            {...item}
          />
        ))}
      </Container>
      {data?.length > 1 && (
        <Flex
          position="absolute"
          top={0}
          left={0}
          alignItems="center"
          justifyContent={
            carouselIdx === 0
              ? "flex-end"
              : carouselIdx === data.length - 1
              ? "flex-start"
              : "space-between"
          }
          display={bp("none", null, "flex")}
          width="100%"
          height="100%"
          padding="0 20px"
        >
          <Button
            hidden={carouselIdx === 0}
            cursor={carouselIdx === 0 ? "not-allowed" : "pointer"}
            onClick={() => scrollCarousel(containerRef, LEFT)}
            tertiary
          >
            <ArrowLeft />
          </Button>
          <Button
            hidden={carouselIdx === data.length - 1}
            cursor={carouselIdx === data.length - 1 ? "not-allowed" : "pointer"}
            onClick={() => scrollCarousel(containerRef, RIGHT)}
            tertiary
          >
            <ArrowRight />
          </Button>
        </Flex>
      )}
    </Box>
  )
}

export default HeroCarousel
