import React from "react"
import styled from "@emotion/styled"
import { AspectRatio, Box, Flex, Grid, Text } from "@chakra-ui/react"
import Card, { CardProps } from "./Card"
import { bp } from "@utils/MediaQueries"

export interface CtaCardProps {
  header?: string
  cards: CardProps[]
}

const Container = styled.div({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  overflowX: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
  width: "100%",
  height: "100%",
})

const CtaCard: React.FC<CtaCardProps> = ({ header, cards }) => {
  return (
    <Flex
      direction="column"
      overflow="hidden"
      padding={bp("3.75rem 1col", null, "10rem 1col")}
    >
      {header && (
        <Text
          w="90%"
          mb={bp("1.875rem", null, "3.375rem")}
          fontFamily="Avenir"
          fontSize={bp("23px", null, "40px")}
          fontWeight="800"
        >
          {header}
        </Text>
      )}
      <Box>
        <Grid
          gap="8px"
          overflowX="auto"
          css={{
            "::-webkit-overflow-scrolling": "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {cards.map((card, idx) => (
            // <Box
            //   key={idx}
            //   flexShrink={0}
            //   w={bp("80vw", null, "3p25col")}
            //   h={bp("calc(100vw * 1.6)", null, "calc(3.25/16 * 100vw * 1.33)")}
            //   mr={bp("8px", null, "0")}
            // >
            //   <Card {...card} flexShrink={0} key={idx} />
            // </Box>
            <Box
              gridRow={1}
              gridColumn={idx}
              width="100%"
              h={"calc(3.25/16 * 100vw * 1.33)"}
              minW="225px"
              minH="289"
              key={idx}
            >
              <Card {...card} key={idx} />
            </Box>
          ))}
        </Grid>
      </Box>
    </Flex>
  )
}

export default CtaCard
