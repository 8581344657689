import React from "react"
import { CmsContentBaseProps } from "../../types"
import ProductSlider from "@components/ProductSlider"
import { getProductUrl } from "@utils/urls"
import _ from "lodash"

interface ComponentProductSliderProps extends CmsContentBaseProps {}

const ComponentProductSlider: React.FC<ComponentProductSliderProps> = ({
  header,
  products,
  cta,
  placeholderImage,
}) => {
  const formattedProducts = products.map((p) => {
    const flattenedProduct = p.variants
      ? _.flatten([
          p,
          ...p.variants.map((v) => ({
            ..._.omit(p, "variants"),
            ...v,
          })),
        ])
      : [p]

    const interiors = _.uniq(
      _.flatten(
        flattenedProduct
          .map(({ interiors }) => interiors)
          .filter((interior) => !!interior)
      )
    )

    const colors = _.uniq(
      _.flatten(
        flattenedProduct.map(({ colors }) => colors).filter((color) => !!color)
      )
    )

    const variants: any = []

    if (colors.length) {
      variants.push(`${colors.length} Color${colors.length > 1 ? "s" : ""}`)
    }

    if (interiors.length) {
      variants.push(
        `${interiors.length} Interior${interiors.length > 1 ? "s" : ""}`
      )
    }

    return {
      name: p.productName,
      releaseDate: p.releaseDate,
      slug: getProductUrl(p.slug),
      image: p.image,
      model: p.sku,
      tag: p.tags,
      price: p.price,
      category: p.category,
      variants: variants.join(", "),
    }
  })
  return (
    <ProductSlider
      placeholderImage={placeholderImage[0].url}
      cta={cta}
      header={header}
      products={formattedProducts}
    />
  )
}

export default ComponentProductSlider
