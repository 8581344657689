import { Text, BoxProps } from "@chakra-ui/react"

export type SmallCapsProps = Omit<BoxProps, "size"> & {
  size?: "large" | "lg" | "small" | "sm"
}

const SmallCaps = ({ size = "large", ...props }: SmallCapsProps) => {
  let fontSize, letterSpacing, lineHeight
  switch (size) {
    case "large":
    case "lg":
      fontSize = "smallCapsLarge"
      letterSpacing = "smallCapsLarge"
      lineHeight = "smallCapsLarge"
      break
    case "small":
    case "sm":
      fontSize = "smallCapsSmall"
      letterSpacing = "smallCapsSmall"
      lineHeight = "smallCapsSmall"
      break
  }

  return (
    <Text
      fontFamily="helvetica"
      fontSize={fontSize}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      fontWeight="medium"
      textTransform="uppercase"
      {...props}
    ></Text>
  )
}

export default SmallCaps
