import React from "react"
import { CmsContentBaseProps } from "../../types"
import HeroCarousel from "@components/HeroCarousel"
import { urlFor } from "@utils/sanity"

interface ComponentHeroCarouselProps extends CmsContentBaseProps {}

const ComponentHeroCarousel: React.FC<ComponentHeroCarouselProps> = ({
  autoplayTimer,
  heroes,
}) => {
  const formattedHeroes = heroes.map((h, idx) => {
    return {
      title: h.title,
      subtitle: h.subtitle,
      loadMode: idx === 0 ? "eager" : "lazy",
      video: h.video || "",
      image: urlFor(h.backgroundImage?.asset).url() || "",
      mobileImage:
        urlFor(h.mobileBackgroundImage?.asset).url() ||
        urlFor(h.backgroundImage?.asset).url() ||
        "",
      displayButton: h.cta.displayButton,
      buttonText: h.cta.label, // TODO: handle CTA
      buttonLink: h.cta.internalLink || h.cta.externalLink,
      isExternal: !!h.cta.externalLink,
    }
  })

  return <HeroCarousel autoplayTimer={autoplayTimer} data={formattedHeroes} />
}

export default ComponentHeroCarousel
