import React from "react"
import { CmsContentBaseProps } from "@cms/types"
import NewsletterForm from "@components/NewsletterForm"

interface ComponentNewsletterFormProps extends CmsContentBaseProps {}

const ComponentNewsletterForm: React.FC<ComponentNewsletterFormProps> = ({
  text,
  buttonText,
  formName,
  formAction,
  hiddenFields,
  inputName,
  inputPlaceholder,
  setAsLastComponent,
}) => {
  return (
    <NewsletterForm
      text={text}
      buttonText={buttonText}
      formName={formName}
      formAction={formAction}
      hiddenFields={hiddenFields}
      inputName={inputName}
      inputPlaceholder={inputPlaceholder}
      setAsLastComponent={setAsLastComponent}
    />
  )
}

export default ComponentNewsletterForm
