import React from "react"
import { CmsContentBaseProps } from "../../types"
import CtaCards from "@components/CtaCards"
import { urlFor } from "@utils/sanity"

interface ComponentCtaCardsProps extends CmsContentBaseProps {}

const ComponentCtaCards: React.FC<ComponentCtaCardsProps> = ({
  header,
  cards,
}) => {
  const formattedCards = cards.map((c) => ({
    title: c.label,
    image: urlFor(c.image?.asset).url() || "",
    mobileImage:
      urlFor(c.mobileImage?.asset).url() || urlFor(c.image?.asset).url() || "",
    slug: c.internalLink || c.externalLink,
    isExternal: !!c.externalLink,
  }))
  return <CtaCards header={header} cards={formattedCards} />
}

export default ComponentCtaCards
