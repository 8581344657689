import { useRouter } from "next/router"
import { Box, BoxProps, css, Flex, useTheme } from "@chakra-ui/react"
import colors from "@constants/colors"
import { PortableText } from "@utils/sanity"
import { bp } from "@utils/MediaQueries"
import { formatUrl } from "@utils/urls"

export interface TextBlockProps extends BoxProps {
  anchorLink?: string
  content: any
  color?: string
  fullWidth?: boolean
  alignText?: string
  alignComponent?: string
  buttonText?: string
  buttonSlug?: string
}

const LEFT = "left"
const CENTER = "center"
const RIGHT = "right"
const FLEX_START = "flex-start"
const FLEX_END = "flex-end"

const configureComponentAlignment = (align: string) => {
  switch (align.toLowerCase()) {
    case LEFT:
      return FLEX_START
    case CENTER:
      return CENTER
    case RIGHT:
      return FLEX_END
    default:
      return CENTER
  }
}

const TextBlock: React.FC<TextBlockProps> = ({
  anchorLink,
  content,
  color,
  fullWidth,
  alignText,
  alignComponent,
  buttonText,
  buttonSlug,
  ...rest
}) => {
  const router = useRouter()
  const theme = useTheme()
  const styling = css({
    alignItems: alignComponent
      ? configureComponentAlignment(alignComponent)
      : "center",
    justifyContent: "center",
    flexDir: "column",
    display: "flex",
    fontFamily: "Avenir",
    color: color || "midAccessible",
    header: {
      fontSize: bp("33px", null, "48px"),
      fontWeight: "800",
    },
    p: {
      justifySelf: "center",
      width: bp("100%", null, fullWidth ? "100%" : "calc(6/16 * 100vw)"),
      padding: fullWidth ? "0" : "0 20px 2rem 20px",
      fontFamily: "Avenir",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      letterSpacing: "0em",
      lineHeight: "22px",
      textAlign: alignText || "center",
      color: colors.midAccessible,
    },
    h1: {
      width: bp("100%", null, fullWidth ? "100%" : "calc(6/16 * 100vw)"),
      marginBottom: "24px",
      padding: fullWidth ? "0" : "0 20px",
      letterSpacing: "0em",
      lineHeight: "60px",
      fontFamily: "Avenir",
      fontSize: bp("33px", null, "48px"),
      fontStyle: "normal",
      fontWeight: "800",
      textAlign: alignText || "center",
      color: colors.dark,
    },
    h2: {
      width: bp("100%", null, fullWidth ? "100%" : "calc(6/16 * 100vw)"),
      padding: fullWidth ? "0" : "0 20px",
      fontFamily: "Avenir",
      textAlign: alignText || "center",
      color: colors.dark,
      fontSize: bp("27px", null, "40px"),
      fontWeight: bp("700", null, "800"),
    },
    h3: {
      width: bp("100%", null, fullWidth ? "100%" : "calc(6/16 * 100vw)"),
      padding: fullWidth ? "0" : "0 20px",
      fontFamily: "Avenir",
      fontSize: bp("21px", "33px"),
      fontWeight: bp("700", null, "800"),
      textAlign: alignText || "center",
      color: colors.dark,
    },
    h4: {
      width: bp("100%", null, fullWidth ? "100%" : "calc(6/16 * 100vw)"),
      padding: fullWidth ? "0" : "0 20px",
      fontFamily: "Avenir",
      fontSize: bp("16px", "19px"),
      fontWeight: bp("700", null, "800"),
      textAlign: alignText || "center",
      color: colors.dark,
    },
    h5: {
      width: bp("100%", null, fullWidth ? "100%" : "calc(6/16 * 100vw)"),
      padding: fullWidth ? "0" : "0 20px",
      fontFamily: "Avenir",
      fontSize: bp("16px", "19px"),
      fontWeight: bp("700", null, "800"),
      textAlign: alignText || "center",
      color: colors.dark,
    },
    h6: {
      width: bp("100%", null, fullWidth ? "100%" : "calc(6/16 * 100vw)"),
      padding: fullWidth ? "0" : "0 20px",
      fontFamily: "Avenir",
      fontSize: bp("16px", "19px"),
      fontWeight: bp("700", null, "800"),
      textAlign: alignText || "center",
      color: colors.dark,
    },
    ul: {
      justifySelf: "center",
      width: bp("100%", null, fullWidth ? "100%" : "calc(6/16 * 100vw)"),
      padding: fullWidth ? "0" : "0 20px 2rem 40px",
      textAlign: alignText || "center",
    },
    li: {
      fontFamily: "Avenir",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      letterSpacing: "0em",
      lineHeight: "22px",
      textAlign: alignText || "center",
      color: colors.midAccessible,
    },
    button: {
      bg: "#000",
      color: "#FFF",
      height: bp("52px", null, "58px"),
      px: "32px",
      rounded: "lg",
      fontSize: bp("13.5px", null, "16px"),
      fontWeight: "800",
    },
    "a:hover": {
      borderBottom: `1px solid ${colors.brand}`,
    },
    a: {
      color: colors.brand,
    },
    div: {
      display: "grid",
    },
  })(theme)

  const containerProps: any = {}

  if (anchorLink) {
    containerProps.id = `${anchorLink}`
  }

  return (
    <Flex css={styling} {...containerProps} {...rest}>
      {content && <PortableText blocks={content} />}
      {buttonText && (
        <Box
          as="button"
          onClick={() => router.push(formatUrl(buttonSlug ? buttonSlug : "/"))}
          _focus={{ boxShadow: "outline", outline: "none" }}
        >
          {buttonText}
        </Box>
      )}
    </Flex>
  )
}

export default TextBlock
