import React from "react"
import { CmsContentBaseProps } from "../../types"
import AnchorNav from "@components/AnchorNav"

interface ComponentAnchorNavProps extends CmsContentBaseProps {}

const ComponentAnchorNav: React.FC<ComponentAnchorNavProps> = ({
  anchorLinks,
  image,
  imagePosition,
  text,
}) => {
  return <AnchorNav anchorLinks={anchorLinks} />
}

export default ComponentAnchorNav
