import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { bp } from "@utils/MediaQueries"
import Link from "@components/Link"
import Spacer from "@components/universal/Spacer"
import { PlayButton } from "@components/universal/icons"
import colors from "@constants/colors"

export interface TitleProps {
  title: string
  subtitle: string
  buttonText: string
  buttonLink: string
  displayButton: boolean
  isExternal: boolean
  video?: string
  onOpen: VoidFunction
}

const Title: React.FC<TitleProps> = ({
  buttonLink,
  buttonText,
  isExternal,
  displayButton,
  subtitle,
  title,
  video,
  onOpen,
}) => {
  const playButtonSize = 75
  return (
    <Box d="flex" alignItems="center" justifyContent="center" flexDir="column">
      <Spacer height="20px" />
      <Text
        width={bp("80%", null, "10col")}
        fontSize={bp("33px", null, "82.5px")}
        fontFamily="Avenir"
        fontWeight="800"
        textAlign="center"
      >
        {title}
      </Text>
      {video && (
        <Box
          width={`${playButtonSize}px`}
          height={`${playButtonSize}px`}
          as="button"
          onClick={onOpen}
        >
          <PlayButton />
        </Box>
      )}
      <Text
        width={bp("68%", null, "6col")}
        margin={bp("1.25rem 0", null, "1.25rem 0 3.125rem")}
        fontSize={bp("16px", null, "19px")}
        fontFamily="Avenir"
        fontWeight="800"
        mb={10}
      >
        {subtitle}
      </Text>
      {displayButton && (
        <Link
          external={isExternal}
          href={buttonLink}
          color="white"
          _hover={{ textDecor: "none" }}
        >
          <Box
            as="button"
            _hover={{ bg: colors.blackButtonFocus }}
            bg="black"
            color="white"
            py={4}
            px={8}
            height="58px"
            padding="0 32px"
            rounded="lg"
            fontWeight="800"
            fontFamily="Avenir"
            fontSize={bp("13.5px", null, "16px")}
            _focus={{ boxShadow: "outline", outline: "none" }}
          >
            {buttonText}
          </Box>
        </Link>
      )}
      <Spacer height="20px" />
    </Box>
  )
}

export default Title
