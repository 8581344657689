import React from "react"
import { Box, Grid } from "@chakra-ui/react"
import DownloadCard, { DownloadCardProps } from "@components/DownloadCard"
import { bp } from "@utils/MediaQueries"

export interface DownloadsProps {
  header: string
  downloads: Array<DownloadCardProps>
}

const Downloads: React.FC<DownloadsProps> = ({ header, downloads }) => {
  return (
    <Box
      p={bp("36px 0 40px", null, "56px 0 76px")}
      borderTop="solid 1px rgba(177, 181, 184, 0.3)"
    >
      <Box
        mb="54px"
        fontFamily="Avenir"
        fontSize="27.5px"
        fontWeight={800}
        lineHeight="36px"
        color="dark"
      >
        {header}
      </Box>
      <Box w="100%">
        <Grid
          w="100%"
          h="100%"
          templateColumns={bp(
            "repeat(1, minmax(0, 1fr))",
            null,
            "repeat(3, minmax(0, 1fr))"
          )}
          templateRows="min-content"
          columnGap={bp("10px", null, "calc((0.75 / 16) * 100vw)")}
          rowGap={bp("24px", null, "calc((0.5 / 16) * 100vw)")}
        >
          {downloads.map((download, idx) => (
            <Box key={idx}>
              <DownloadCard
                headerFontSize="16px"
                metaFontSize="13.5px"
                {...download}
              />
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default Downloads
