import React from "react"
import { CmsContentBaseProps } from "../../types"
import Hero from "@components/Hero"
import { urlFor } from "@utils/sanity"
import { isExternal } from "util/types"

interface ComponentHeroProps extends CmsContentBaseProps {}

const ComponentHero: React.FC<ComponentHeroProps> = ({
  title,
  subtitle,
  video,
  backgroundImage,
  mobileBackgroundImage,
  cta,
}) => {
  return (
    <Hero
      title={title}
      subtitle={subtitle}
      video={video || ""}
      image={urlFor(backgroundImage?.asset).url() || ""}
      mobileImage={
        urlFor(mobileBackgroundImage?.asset).url() ||
        urlFor(backgroundImage?.asset).url() ||
        ""
      }
      displayButton={cta.displayButton}
      buttonText={cta.label}
      buttonLink={cta.internalLink || cta.externalLink}
      isExternal={cta.isExternal}
    />
  )
}

export default ComponentHero
