import React from "react"
import { CmsUnionType } from "../types"
import AnchorNav from "./components/ComponentAnchorNav"
import Banner from "./components/ComponentBanner"
import BannerCarousel from "./components/ComponentBannerCarousel"
import CardSlider from "./components/ComponentCardSlider"
import ContentBlock from "./components/ComponentContentBlock"
import CtaCards from "./components/ComponentCtaCards"
import Downloads from "./components/ComponentDownloads"
import Faq from "./components/ComponentFaq"
import FeatureBlock from "./components/ComponentFeatureBlock"
import Hero from "./components/ComponentHero"
import HeroCarousel from "./components/ComponentHeroCarousel"
import ImageGrid from "./components/ComponentImageGrid"
import LegalContent from "./components/ComponentLegalContent"
import LogoGrid from "./components/ComponentLogoGrid"
import MenuAccordion from "./components/ComponentMenuAccordion"
import NewsletterForm from "./components/ComponentNewsletterForm"
import ProductSlider from "./components/ComponentProductSlider"
import Spacer from "./components/ComponentSpacer"
import TextBlock from "./components/ComponentTextBlock"
import VerticalDotSpacer from "./components/ComponentVerticalDotSpacer"
import VerticalSpace from "./components/ComponentVerticalSpace"
import YoutubeVideo from "./components/ComponentYoutubeVideo"
import { toTitle } from "@utils/index"

const components = {
  AnchorNav,
  Banner,
  BannerCarousel,
  CardSlider,
  ContentBlock,
  CtaCards,
  Downloads,
  Faq,
  FeatureBlock,
  Hero,
  HeroCarousel,
  ImageGrid,
  LegalContent,
  LogoGrid,
  MenuAccordion,
  NewsletterForm,
  ProductSlider,
  Spacer,
  TextBlock,
  VerticalDotSpacer,
  VerticalSpace,
  YoutubeVideo,
}

const renderContent = (
  content: CmsUnionType | CmsUnionType[] | null,
  position: number
): React.ReactNode => {
  // Preconditions
  if (content == null) {
    console.warn("renderContent, received undefined content", content)
    return null
  }

  if (Array.isArray(content)) {
    return content.map(renderContent)
  }

  // Render component
  const { _key, _type, ...rest } = content

  const Component = _type && components[toTitle(_type)]
  if (!_type) {
    console.warn("No type provided")
    return null
  } else if (!Component) {
    console.warn("Component not found for", toTitle(_type), rest)
    return null
  }

  return (
    <Component
      renderContent={renderContent}
      position={position}
      key={_key || position}
      id={_key || position}
      {...rest}
    />
  )
}

interface ContentfulContentProps {
  content: any[]
}

const Component: React.FC<ContentfulContentProps> = ({ content }) => (
  <>{content && Array.isArray(content) ? content.map(renderContent) : null}</>
)

export default Component
