import { Box, Grid, css, Flex, Text, useTheme } from "@chakra-ui/react"
import { PortableText } from "@utils/sanity"

import { bp } from "@utils/MediaQueries"
import Colors from "@constants/colors"
import React from "react"

export interface ILegalContentProps {
  title?: string
  content: any
}

const LegalContent: React.FC<ILegalContentProps> = ({
  content,
  title,
  ...rest
}) => {
  const theme = useTheme()
  const styles = css({
    overflow: "hidden",
    width: "100%",
    fontFamily: "Avenir",
    flexDir: "column",

    alignItems: "center",
    paddingX: bp(".75rem", null, "0"),
    paddingY: "1rem",
    maxWidth: "calc(10/16 * 100vw)",
    // minWidth: "calc(10/16 * 100vw)",
    header: {
      fontSize: bp("23px", null, "27.5px"),
      fontWeight: "800",
      paddingTop: "2rem",
    },
    p: {
      color: Colors.midAccessible,
      paddingTop: "1rem",
      paddingBottom: "2rem",
      maxWidth: "calc(10/16 * 100vw)",
      width: bp("100%", null, "100%"),
    },
    "a:hover": {
      borderBottom: `1px solid ${Colors.brand}`,
    },
    a: {
      color: Colors.brand,
    },
    blockquote: {
      paddingLeft: "2rem",
      color: Colors.midAccessible,
      maxWidth: "calc(10/16 * 100vw)",
      width: bp("100%", null, "100%"),
    },
    ul: {
      paddingLeft: "2rem",
      color: Colors.midAccessible,
      maxWidth: "calc(10/16 * 100vw)",
      width: bp("100%", null, "100%"),
    },
    li: {
      color: Colors.midAccessible,
      maxWidth: "calc(10/16 * 100vw)",
      width: bp("100%", null, "100%"),
    },
    h1: {
      fontWeight: bp("700", null, "800"),
      fontSize: bp("33px", null, "48px"),
    },
    h2: {
      fontSize: bp("27px", null, "40px"),
      fontWeight: bp("700", null, "800"),
    },
    h3: {
      fontSize: bp("21px", "33px"),
      fontWeight: bp("700", null, "800"),
    },
    h4: {
      fontSize: bp("16px", "19px"),
      fontWeight: bp("700", null, "800"),
    },
    h5: {
      fontSize: bp("16px", "19px"),
      fontWeight: bp("700", null, "800"),
    },
    h6: {
      fontSize: bp("16px", "19px"),
      fontWeight: bp("700", null, "800"),
    },
    strong: {
      color: "#000 !important",
      fontWeight: "800",
    },
  })(theme)

  return (
    <Grid width="100%" justifyItems="center">
      <Box width="100%" maxWidth="calc(10/16 * 100vw)">
        <Grid css={styles} {...rest}>
          {content && <PortableText blocks={content} />}
        </Grid>
      </Box>
    </Grid>
  )
}

export default LegalContent
