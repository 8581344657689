import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Masonry from "react-masonry-css"
import Link from "@components/Link"
import { AltShoppingCart } from "@components/universal/icons"
import { bp } from "@utils/MediaQueries"
import Image from "next/image"

const Container = styled.div<{ spacing: string; mobileSpacing: string }>`
  ${({ spacing, mobileSpacing }) => css`
    padding: 32px 20px 80px;

    .masonry-grid {
      display: flex;
      width: auto;
      margin-left: -${mobileSpacing};
    }

    .masonry-grid__column {
      padding-left: ${mobileSpacing};
      background-clip: padding-box;
    }

    .masonry-grid__column > div {
      margin-bottom: ${mobileSpacing};
    }

    @media only screen and (min-width: 1024px) {
      padding: 60px calc(1 / 16 * 100vw) 90px;

      .masonry-grid {
        margin-left: -${spacing};
      }

      .masonry-grid__column {
        padding-left: ${spacing};
      }

      .masonry-grid__column > div {
        margin-bottom: ${spacing};
      }
    }
  `}
`

export interface ImageProps {
  url: string
  internalLink?: string
  externalLink?: string
}

export interface ImageGridProps {
  title: string
  images: ImageProps[]
  spacing?: string
  mobileSpacing?: string
}

const URL = "https://instagram.com/skbcases"
const URL_TEXT = "instagram.com/skbcases"

const ImageGrid: React.FC<ImageGridProps> = ({
  title,
  images,
  spacing,
  mobileSpacing,
}) => (
  <Container
    spacing={spacing || "24px"}
    mobileSpacing={mobileSpacing || "24px"}
  >
    <Box mb={bp("32px", null, "86px")} textAlign="center">
      <Text
        fontFamily="Avenir"
        fontSize={bp("23px", null, "40px")}
        fontWeight="800"
      >
        {title}
      </Text>
      <Link external href={URL}>
        <Text
          fontFamily="Avenir"
          fontSize={bp("13.5px", null, "16px")}
          fontWeight="800"
          textAlign="center"
        >
          {URL_TEXT}
        </Text>
      </Link>
    </Box>
    <Masonry
      breakpointCols={{ default: 3, 1024: 2 }}
      className="masonry-grid"
      columnClassName="masonry-grid__column"
    >
      {images?.map((img, idx) => {
        const isExternal = img.externalLink !== ""
        const link = img.externalLink || img.internalLink || ""
        return (
          <Box key={idx} pos="relative" w="auto" height="auto">
            <Flex
              pos="absolute"
              top={bp("12px", null, "30px")}
              right={bp("12px", null, "30px")}
              alignItems="center"
              justifyContent="center"
              h={bp("26px", null, "48px")}
              w={bp("26px", null, "48px")}
              borderRadius="50%"
              bg="rgba(204, 210, 213, .7)"
              zIndex={1}
              _hover={{
                cursor: "pointer",
              }}
            >
              <Link
                external={isExternal}
                href={link}
                color="white"
                _hover={{ textDecor: "none" }}
              >
                <AltShoppingCart
                  w={bp("18px", null, "32px")}
                  h={bp("18px", null, "32px")}
                />
              </Link>
            </Flex>
            <Image
              width="100%"
              height="100%"
              layout="responsive"
              objectFit="contain"
              src={img.url}
              alt="Social Media Image"
            />
          </Box>
        )
      })}
    </Masonry>
  </Container>
)

export default ImageGrid
