import React from "react"
import { CmsContentBaseProps } from "../../types"
import CardSlider from "@components/CardSlider"
import { urlFor } from "@utils/sanity"

interface ComponentCardSliderProps extends CmsContentBaseProps {}

const ComponentCardSlider: React.FC<ComponentCardSliderProps> = ({ cards }) => {
  const formattedCards = cards.map((c) => ({
    label: c.label,
    image: urlFor(c.image?.asset).url() || "",
    mobileImage:
      urlFor(c.mobileImage?.asset).url() || urlFor(c.image?.asset).url() || "",
    link: c.internalLink || c.externalLink,
    isExternal: !!c.externalLink,
  }))

  return <CardSlider cards={formattedCards} />
}

export default ComponentCardSlider
