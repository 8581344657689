import React from "react"
import ReactDOM from "react-dom"
import { Box, Flex, Text } from "@chakra-ui/react"
import { bp } from "@utils/MediaQueries"

export interface NewsletterFormProps {
  text: string
  buttonText: string
  formName: string
  formAction: string
  hiddenFields: Array<{
    name: string
    value: string
  }>
  inputName: string
  inputPlaceholder: string
  setAsLastComponent?: boolean
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({
  text,
  buttonText,
  formName,
  formAction,
  hiddenFields,
  inputName,
  inputPlaceholder,
  setAsLastComponent,
}) => {
  const [_document, setDocument] = React.useState<any>(null)

  React.useEffect(() => {
    setDocument(document)
  }, [])

  const PortalContainer = _document && _document.getElementById("endOfContent")
  const FormComponent = (
    <Box
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      display="flex"
      height="247px"
      px={bp("20px", null, 0)}
      borderBottom="1px solid gray"
      bg="black"
      color="white"
    >
      <Flex justifyContent="center" width="100%" mb="1rem">
        <Text
          px={bp("20px", null, 0)}
          fontFamily="Avenir"
          fontSize={"19px"}
          fontWeight="800"
          textAlign="center"
        >
          {text}
        </Text>
      </Flex>
      <Flex width={bp("100%", null, "540px")} px={bp("1rem", null, 0)}>
        <form
          name={formName}
          action={formAction}
          target="_blank"
          method="post"
          style={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            as="input"
            name={inputName}
            placeholder={inputPlaceholder}
            flex="1"
            h="52px"
            py={2}
            px={4}
            rounded="md"
            roundedRight="0"
            bg="#373737"
            border="0"
            borderWidth="1px"
            color="white !important"
            _focus={{
              outline: "none",
              borderColor: "gray.300",
              boxShadow: "outline",
            }}
          />
          {hiddenFields &&
            hiddenFields.map(({ name, value }, i) => (
              <input
                type="hidden"
                key={`${i}-${name}`}
                name={name}
                value={value}
              />
            ))}
          <Box
            as="button"
            h="52px"
            ml={0}
            py={2}
            px={4}
            bg="white"
            rounded="md"
            roundedLeft="0"
            fontFamily="Avenir"
            fontWeight="800"
            color="black"
            _focus={{ boxShadow: "outline" }}
          >
            {buttonText}
          </Box>
        </form>
      </Flex>
    </Box>
  )
  return setAsLastComponent && PortalContainer
    ? ReactDOM.createPortal(FormComponent, PortalContainer)
    : FormComponent
}

export default NewsletterForm
