import React from "react"
import { CmsContentBaseProps } from "../../types"
import TextBlock from "@components/TextBlock"

interface ComponentTextBlockProps extends CmsContentBaseProps {}

const ComponentTextBlock: React.FC<ComponentTextBlockProps> = ({
  anchorLink,
  content,
  color,
  fullWidth,
  alignText,
  alignComponent,
  buttonText,
  buttonSlug,
}) => {
  return (
    <TextBlock
      anchorLink={anchorLink}
      content={content}
      color={color}
      fullWidth={fullWidth}
      alignText={alignText}
      alignComponent={alignComponent}
      buttonText={buttonText}
      buttonSlug={buttonSlug}
    />
  )
}

export default ComponentTextBlock
