import React from "react"
import { Box, Flex, css, useTheme } from "@chakra-ui/react"
import { ArrowLeft, ArrowRight } from "@components/universal/icons"
import Button from "@components/universal/Button"
import Banner, { BannerProps } from "@components/Banner"
import { bp } from "@utils/MediaQueries"

export interface BannerCarouselProps {
  data: any
}

const LEFT = "LEFT"
const RIGHT = "RIGHT"

const BannerCarousel: React.FC<BannerCarouselProps> = ({ data, ...rest }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [carouselIdx, setCarouselIdx] = React.useState(0)

  const scrollCarousel = (myRef: any, direction: string) => {
    const currentWidth = myRef.current.offsetWidth
    let currentIdx = carouselIdx

    if (direction === RIGHT) {
      myRef.current.scrollLeft += currentWidth
      carouselIdx !== data.length - 1 && setCarouselIdx((currentIdx += 1))
    } else if (direction === LEFT) {
      myRef.current.scrollLeft -= currentWidth
      carouselIdx !== 0 && setCarouselIdx((currentIdx -= 1))
    }
  }

  const theme = useTheme()
  const container = css({
    position: "relative",
    flexWrap: "nowrap",
    display: "flex",
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  })(theme)

  if (Array.isArray(data)) {
    return (
      <Box position="relative" {...rest}>
        <Box css={container} ref={containerRef}>
          {data?.map((item, idx) => (
            <Banner {...item} key={idx} />
          ))}
        </Box>
        {data?.length > 1 && (
          <Flex
            position="absolute"
            top={0}
            left={0}
            alignItems="center"
            justifyContent={
              carouselIdx === 0
                ? "flex-end"
                : carouselIdx === data.length - 1
                ? "flex-start"
                : "space-between"
            }
            display={bp("none", null, "flex")}
            width="100%"
            height="100%"
            padding="0 20px"
          >
            <Button
              hidden={carouselIdx === 0}
              cursor={carouselIdx === 0 ? "not-allowed" : "pointer"}
              onClick={() => scrollCarousel(containerRef, LEFT)}
              tertiary
            >
              <ArrowLeft stroke="#B1B5B8" />
            </Button>
            <Button
              hidden={carouselIdx === data.length - 1}
              cursor={
                carouselIdx === data.length - 1 ? "not-allowed" : "pointer"
              }
              onClick={() => scrollCarousel(containerRef, RIGHT)}
              tertiary
            >
              <ArrowRight stroke="#B1B5B8" />
            </Button>
          </Flex>
        )}
      </Box>
    )
  }

  return (
    <Box css={container}>
      <Banner {...data} />
    </Box>
  )
}

export default BannerCarousel
