import React from "react"
import styled from "@emotion/styled"
import colors from "@constants/colors"

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
`

const Dot = styled.div`
  width: 4px;
  height: 4px;
  margin: 11px 0;
  border-radius: 50%;
  background-color: ${colors.midLight};
`

export interface VerticalDotSpacerProps {
  dots: number
}

const VerticalDotSpacer: React.FC<VerticalDotSpacerProps> = ({ dots }) => {
  // @ts-ignore-next
  const Dots = [...Array(dots).keys()] as any

  return (
    <Container>
      {Dots.map((_, index) => (
        <Dot key={index} />
      ))}
    </Container>
  )
}

export default VerticalDotSpacer
