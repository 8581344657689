import React from "react"
import { Box, BoxProps, css, useDisclosure } from "@chakra-ui/react"
import Image from "next/image"
import Title from "./Title"
import { bp, LaptopUpOnly, MobileOnly } from "@utils/MediaQueries"
import { VideoModal } from "@components/VideoModal"

export interface HeroProps extends Omit<BoxProps, "title" | "children"> {
  image: string
  mobileImage?: string
  title: string
  subtitle: string
  buttonText: string
  buttonLink: string
  isExternal: boolean
  displayButton: boolean
  video?: any
  loadMode?: "eager" | "lazy" | undefined
  handleOnOpen?: VoidFunction
  handleOnClose?: VoidFunction
}

const Hero: React.FC<HeroProps> = ({
  buttonLink,
  buttonText,
  image,
  isExternal,
  mobileImage,
  subtitle,
  title,
  video,
  displayButton,
  loadMode = "eager",
  handleOnOpen,
  handleOnClose,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Box
        position="relative"
        flexShrink={0}
        alignItems="center"
        justifyContent="center"
        display="flex"
        w="100%"
        minH={bp("calc(100vh - 72px)", null, "calc(100vh - 142px)")}
        textAlign="center"
        color="white"
        {...rest}
      >
        <Box
          zIndex={0}
          position="absolute"
          overflow="hidden"
          w="100%"
          h="100%"
          css={css({
            img: {
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          })}
        >
          <MobileOnly>
            <Image
              src={image}
              alt="Hero Image"
              quality={80}
              loading={loadMode}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </MobileOnly>
          <LaptopUpOnly>
            <Image
              src={mobileImage || image}
              alt={title || "Hero Image"}
              quality={80}
              loading={loadMode}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </LaptopUpOnly>
        </Box>
        <Box display="flex" zIndex={1}>
          <Title
            title={title}
            subtitle={subtitle}
            displayButton={displayButton}
            buttonText={buttonText}
            buttonLink={buttonLink}
            isExternal={isExternal}
            video={video}
            onOpen={() => {
              onOpen()

              if (handleOnOpen) {
                handleOnOpen()
              }
            }}
          />
        </Box>
      </Box>
      <VideoModal
        embedId={video}
        isOpen={isOpen}
        onClose={() => {
          onClose()

          if (handleOnClose) {
            handleOnClose()
          }
        }}
      />
    </>
  )
}
export default Hero
