import { Box, BoxProps } from "@chakra-ui/react"
import Link from "@components/Link"
import { bp } from "@utils/MediaQueries"

export interface AnchorNavProps extends Omit<BoxProps, "children" | "title"> {
  anchorLinks: Array<{
    anchor: string
    label: string
  }>
}

const AnchorNav: React.FC<AnchorNavProps> = ({ anchorLinks, ...rest }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={"75px"}
      position="fixed"
      width="100%"
      bg="#FFF"
      zIndex="2"
      {...rest}
    >
      {anchorLinks &&
        anchorLinks.map(({ anchor, label }, idx) => {
          return (
            <Box
              onClick={(e) => {
                const ref = document.getElementById(anchor)
                if (ref && ref.offsetTop) {
                  window.scrollTo({
                    top: ref.offsetTop - 145,
                    behavior: "smooth",
                  })
                }
              }}
              cursor="pointer"
              key={idx}
              px={bp("1rem", null, "2rem")}
              flexShrink={0}
              fontFamily="Avenir"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="800"
              lineHeight="20px"
              letterSpacing="0em"
              textAlign="center"
              opacity="50%"
            >
              {label}
            </Box>
          )
        })}
    </Box>
  )
}

export default AnchorNav
