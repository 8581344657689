import React from "react"
import { CmsContentBaseProps } from "../../types"
import ContentBlock from "@components/ContentBlock"
import { urlFor } from "@utils/sanity"

interface ComponentContentBlockProps extends CmsContentBaseProps {}

const ComponentContentBlock: React.FC<ComponentContentBlockProps> = ({
  anchorLink,
  image,
  imagePosition,
  text,
}) => {
  return (
    <ContentBlock
      anchorLink={anchorLink}
      image={(image && urlFor(image.asset).url()) || ""}
      imagePosition={imagePosition}
      text={text}
    />
  )
}

export default ComponentContentBlock
