import React from "react"
import { CmsContentBaseProps } from "../../types"
import LogoGrid from "@components/LogoGrid"

interface ComponentLogoGridProps extends CmsContentBaseProps {}

const ComponentLogoGrid: React.FC<ComponentLogoGridProps> = ({ logos }) => {
  return <LogoGrid logos={logos} />
}

export default ComponentLogoGrid
