import React from "react"
import { CmsContentBaseProps } from "../../types"
import YoutubeVideo from "@components/YoutubeVideo"

interface ComponentYoutubeVideoProps extends CmsContentBaseProps {}

const ComponentYoutubeVideo: React.FC<ComponentYoutubeVideoProps> = ({
  embedId,
}) => {
  return <YoutubeVideo embedId={embedId} />
}

export default ComponentYoutubeVideo
