import {
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  CollapseProps,
  Text,
} from "@chakra-ui/react"

import Colors from "@constants/colors"
import React from "react"
import { LaptopUpOnly, MobileOnly } from "../../utils/MediaQueries"

export interface AccordionItemProps {
  question: string
  answer: string
}

export interface AccordionMenuProps {
  data: AccordionItemProps[]
  headerProps?: AccordionButtonProps
  panelProps?: Omit<CollapseProps, "children">
  children?: React.ReactNode
}

const MenuAccordion: React.FC<AccordionMenuProps> = ({
  data,
  headerProps,
  panelProps,
  children,
  ...rest
}) => {
  return (
    <>
      {data?.map((item, idx) => (
        <React.Fragment key={`${item.question}-${idx}`}>
          <MobileOnly>
            <Accordion allowMultiple>
              <AccordionItem {...rest}>
                <AccordionButton
                  _focus={{ border: "0px hidden black" }}
                  borderColor="light"
                  py="m"
                  px={0}
                  display="flex"
                  justifyContent="space-between"
                  {...headerProps}
                >
                  <Text
                    fontFamily="Avenir"
                    fontWeight="800"
                    fontSize="16px"
                    textAlign="start"
                    py=".5rem"
                  >
                    {item.question}
                  </Text>
                  <AccordionIcon color="dark" />
                </AccordionButton>
                <AccordionPanel p="1rem 0">
                  <Text
                    color={Colors.midAccessible}
                    fontFamily="Avenir"
                    fontSize="16px"
                    py=".5rem"
                  >
                    {item.answer}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </MobileOnly>
          <LaptopUpOnly>
            <Accordion allowMultiple>
              <AccordionItem {...rest} key={`${item.question}-${idx}`}>
                <AccordionButton
                  _focus={{ border: "0px hidden black" }}
                  display="flex"
                  justifyContent="space-between"
                  borderColor="light"
                  py="m"
                  px={0}
                  {...headerProps}
                >
                  <Text
                    fontFamily="Avenir"
                    fontWeight="800"
                    fontSize="16px"
                    py=".5rem"
                  >
                    {item.question}
                  </Text>
                  <AccordionIcon color="dark" />
                </AccordionButton>
                <AccordionPanel p="1rem 0">
                  <Text
                    fontFamily="Avenir"
                    fontSize="16px"
                    color={Colors.midAccessible}
                    py=".5rem"
                  >
                    {item.answer}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </LaptopUpOnly>
        </React.Fragment>
      ))}
    </>
  )
}

export default MenuAccordion
