import { Box, Grid } from "@chakra-ui/react"
import Image from "next/image"

import { bp } from "@utils/MediaQueries"

export interface ILogoGridProps {
  logos: any[]
}

const LogoGrid: React.FC<ILogoGridProps> = ({ logos }) => {
  return (
    <Grid
      width={"calc(13/16*100vw)"}
      margin="0 auto"
      templateColumns={bp("repeat(2, 1fr)", null, "repeat(4, 1fr)")}
      gridColumnGap={bp("3rem", null, "1rem")}
      gridRowGap={bp("1rem", null, "5rem")}
    >
      {logos?.map((logo, idx) => (
        <Box
          key={idx}
          height="95px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Image
            src={logo.image}
            layout="fill"
            objectFit="scale-down"
            objectPosition="center"
          />
        </Box>
      ))}
    </Grid>
  )
}

export default LogoGrid
