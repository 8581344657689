import React from "react"
import styled from "@emotion/styled"
import { Box, BoxProps, Flex } from "@chakra-ui/react"
import Image from "next/image"
import { PortableText } from "@utils/sanity"
import { bp } from "@utils/MediaQueries"

export enum ImagePosition {
  LEFT = "Left",
  RIGHT = "Right",
}

const PortableTextContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 50px 0 0;

  h3 {
    margin-bottom: 24px;
    font-family: "Avenir";
    font-size: 23px;
    font-weight: 800;
    line-height: 30px;
  }

  h6 {
    font-family: "Avenir";
  }

  p {
    font-family: "Avenir";
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 1024px) {
    align-items: center;
    justify-content: center;
    width: calc(7 / 16 * 100vw);
    padding: 0 calc(1.5 / 16 * 100vw);

    h3 {
      margin-bottom: 22px;
      font-family: "Avenir";
      font-size: 33px;
      font-weight: 800;
      line-height: 40px;
    }
  }
`

export interface ContentBlockProps
  extends Omit<BoxProps, "children" | "title"> {
  anchorLink?: string
  image: string
  imagePosition?: ImagePosition
  text: any
}

const ContentBlock: React.FC<ContentBlockProps> = ({
  anchorLink,
  image,
  imagePosition,
  text,
  ...rest
}) => {
  const containerProps: any = {}

  if (anchorLink) {
    containerProps.id = `${anchorLink}`
  }

  return (
    <Flex
      {...containerProps}
      padding={bp("40px 20px", null, "140px 0 120px 0")}
      flexDir={bp(
        "column",
        null,
        imagePosition === ImagePosition.LEFT ? "row" : "row-reverse"
      )}
    >
      {image && (
        <Box
          position="relative"
          width={bp("calc(100vw - 40px)", null, "9col")}
          height={bp("calc((100vw - 40px) * 0.78)", null, "7col")}
        >
          <Image
            src={image}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </Box>
      )}
      <PortableTextContainer>
        {text && <PortableText blocks={text} />}
      </PortableTextContainer>
    </Flex>
  )
}

export default ContentBlock
