import { Box, BoxProps } from "@chakra-ui/react"
import SmallCaps from "../../typography/SmallCaps"

export interface TagProps extends BoxProps {
  tagColor?: string
}

const Tag: React.FC<TagProps> = ({ children, tagColor, ...rest }) => (
  <Box
    bg={tagColor || "primary"}
    display="inline-block"
    p=".25rem .5rem"
    borderRadius=".25rem"
    {...rest}
  >
    <SmallCaps size="sm" color="white">
      {children}
    </SmallCaps>
  </Box>
)
export default Tag
