import React from "react"
import { AspectRatio } from "@chakra-ui/react"

export interface YoutubeVideoProps {
  embedId: string
}

const YoutubeVideo: React.FC<YoutubeVideoProps> = ({ embedId }) => (
  <AspectRatio ratio={16 / 9}>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?rel=0`}
      allow="autoplay; encrypted-media"
      allowFullScreen
      frameBorder="0"
      title="video"
    />
  </AspectRatio>
)

export default YoutubeVideo
