import React from "react"
import { CmsContentBaseProps } from "../../types"
import FeatureBlock from "@components/FeatureBlock"

interface ComponentFeatureBlockProps extends CmsContentBaseProps {}

const ComponentFeatureBlock: React.FC<ComponentFeatureBlockProps> = ({
  title,
  subtext,
  subtitle,
  featureItems,
}) => {
  return (
    <FeatureBlock
      title={title}
      subtext={subtext}
      subtitle={subtitle}
      featureItems={featureItems}
    />
  )
}

export default ComponentFeatureBlock
