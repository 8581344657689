import React from "react"
import { Box, BoxProps, Text, css, useTheme } from "@chakra-ui/react"
import colors from "@constants/colors"
import Image from "next/image"
import Link from "@components/Link"
import Tag from "@components/universal/Tag"
import Spacer from "@components/universal/Spacer"
import { bp } from "@utils/MediaQueries"

export interface ProductProps extends Omit<BoxProps, "children" | "name"> {
  slug: string
  releaseDate: string
  tag?: string
  image: string
  name: string
  category: string
  variants?: string
  model?: string
  price: string
}

export interface ProductCardProps {
  product: ProductProps
  placeholderImage: string
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  placeholderImage,
  ...rest
}) => {
  const {
    slug,
    tag,
    image,
    name,
    category,
    variants,
    model,
    price,
    releaseDate,
  } = product

  const theme = useTheme()
  const imageContainerStyles = css({
    img: {
      padding: bp(
        "20px !important",
        null,
        "calc((0.25 / 12) * 100vw) !important"
      ),
    },
  })(theme)

  function addDays(date, days) {
    date.setUTCDate(days)
    return date
  }

  const showTag = new Date(releaseDate) >= addDays(new Date(), -60)

  return (
    <Box
      flexShrink={0}
      w={bp("183px", null, "4p5col")}
      h="100%"
      mt={bp("8px", null, "16px")}
      mr={bp("8px", null, "16px")}
    >
      <Link href={slug} useAnchor>
        <Box
          pos="relative"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          display="flex"
          overflow="hidden"
          width={bp("183px", null, "4p5col")}
          height={bp("183px", null, "calc((4.5 / 16) * 100vw)")}
          padding={"2rem"}
          bg={colors.light}
          cursor="pointer"
          css={imageContainerStyles}
          {...rest}
        >
          <Image
            src={image || placeholderImage}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
          {showTag && (
            <Tag
              pos="absolute"
              top={bp("10px", null, "lg")}
              left={bp("10px", null, "lg")}
              tagColor="#C8102E"
            >
              {"New"}
            </Tag>
          )}
        </Box>
      </Link>
      <Box
        alignSelf="flex-start"
        width={bp("183px", null, "4p5col")}
        mt={bp("1rem", null, "2rem")}
        mb={bp("1rem", null, "2rem")}
        pl={bp(".5rem", null, "0")}
        bg={colors.white}
      >
        <Text
          fontFamily="Avenir"
          fontSize={bp("13.5px", null, "19px")}
          fontWeight="800"
        >
          {name}
        </Text>
        <Text
          fontFamily="Avenir"
          fontSize={bp("13.5px", null, "16px")}
          fontWeight="500"
          color={colors.midAccessible}
        >
          {model}
        </Text>
        {variants && (
          <Text
            fontFamily="Avenir"
            fontSize={bp("13.5px", null, "16px")}
            fontWeight="500"
            color={colors.midAccessible}
          >
            {variants}
          </Text>
        )}
        <Spacer />
        <Text
          fontFamily="Avenir"
          fontSize={bp("13.5px", null, "16px")}
          fontWeight="800"
          color={colors.dark}
        >
          {`$${price}`}
        </Text>
      </Box>
    </Box>
  )
}

export default ProductCard
