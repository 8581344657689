import React from "react"
import styled from "@emotion/styled"
import { Box, Flex } from "@chakra-ui/react"
import Card, { CardProps } from "./Card"
import Arrows from "./Arrows"
import { bp } from "@utils/MediaQueries"

export interface CardSliderProps {
  cards: CardProps[]
}

const Container = styled.div({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  overflowX: "auto",
  WebkitOverflowScrolling: "touch",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
  width: "100%",
})

const CardSlider: React.FC<CardSliderProps> = ({ cards }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <Flex
      position="relative"
      direction="column"
      overflow="hidden"
      padding={bp("70px 0 140px 20px", null, "120px 1col")}
      paddingRight={bp(0, null, 0)}
    >
      <Container ref={containerRef}>
        {cards.map((card, idx) => (
          <Box
            key={idx}
            flexShrink={0}
            w={bp("80vw", null, "4p5col")}
            h={bp("calc(100vw * 1.6)", null, "calc((4.25 / 16) * 100vw * .75)")}
            mr={bp("8px", null, "16px")}
          >
            <Card {...card} flexShrink={0} key={idx} />
          </Box>
        ))}
      </Container>
      <Arrows containerRef={containerRef} />
    </Flex>
  )
}

export default CardSlider
