import colors from "@constants/colors"
import {
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  css,
  useTheme,
} from "@chakra-ui/react"
import { bp } from "@utils/MediaQueries"
import { PortableText } from "@utils/sanity"

export const portalTextStyles = {
  fontFamily: "Avenir",
  header: {
    fontWeight: "800",
    fontSize: bp("33px", null, "48px"),
  },
  p: {
    padding: "0 0 28px",
    color: colors.midAccessible,
  },
  h1: {
    fontWeight: bp("700", null, "800"),
    fontSize: bp("33px", null, "48px"),
    color: colors.dark,
  },
  h2: {
    fontSize: bp("27px", null, "40px"),
    fontWeight: bp("700", null, "800"),
    color: colors.dark,
  },
  h3: {
    fontSize: bp("21px", "33px"),
    fontWeight: bp("700", null, "800"),
    color: colors.dark,
  },
  h4: {
    fontSize: bp("16px", "19px"),
    fontWeight: bp("700", null, "800"),
    color: colors.dark,
  },
  h5: {
    fontSize: bp("16px", "19px"),
    fontWeight: bp("700", null, "800"),
    color: colors.dark,
  },
  h6: {
    fontSize: bp("16px", "19px"),
    fontWeight: bp("700", null, "800"),
    color: colors.dark,
  },
  ul: {
    color: colors.midAccessible,
  },
  li: {
    width: bp("calc(100vw - 50px)", "calc(100vw - 50px)", "100%", "100%"),
    wordWrap: "break-word",
    color: colors.midAccessible,
  },
  "a:hover": {
    borderBottom: `1px solid ${colors.brand}`,
  },
  a: {
    color: colors.brand,
  },
}

export interface FaqProps {
  title: string
  questionsAndAnswers: Array<{
    question: string
    answer: any
  }>
}

const Faq: React.FC<FaqProps> = ({ title, questionsAndAnswers }) => {
  const theme = useTheme()
  const styles = css(portalTextStyles)(theme)

  return (
    <Accordion allowMultiple>
      {questionsAndAnswers.map((item, i) => (
        <AccordionItem key={i} borderColor={colors.midLight}>
          <AccordionButton
            _focus={{ border: "0px hidden transparent" }}
            justifyContent="space-between"
            display="flex"
            p="28px 0"
          >
            <Text
              py={0}
              fontFamily="Avenir"
              fontWeight="800"
              fontSize="16px"
              textAlign="start"
            >
              {item.question}
            </Text>
            <AccordionIcon color={colors.dark} />
          </AccordionButton>
          <AccordionPanel p="0">
            <Box css={styles} m="0" p="0">
              <PortableText blocks={item.answer || []} />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default Faq
