import React from "react"
import {
  AspectRatio,
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react"
import { Video } from "@components/Video"

export interface VideoProps {
  embedId: any
  isOpen: boolean
  onClose: VoidFunction
}

export const VideoModal: React.FC<VideoProps> = ({
  embedId,
  isOpen,
  onClose,
}) => {
  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(12,12,12,.9)" />
      <ModalContent bg="invisible">
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          width="100vw"
          height="100vh"
          onClick={onClose}
        >
          <Box width="65%">
            <Video
              videoURL={`https://www.youtube.com/embed/${
                embedId && embedId[0].embedId
              }?rel=0`}
            />
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  )
}
