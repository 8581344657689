import Image from "next/image"
import { Link, Box, Text } from "@chakra-ui/react"
import { LaptopUpOnly, MobileOnly } from "@utils/MediaQueries"
import colors from "@constants/colors"
import { bp } from "@utils/MediaQueries"

export interface DownloadCardProps {
  title: string
  image: string
  mobileImage?: string
  download: string
  metaData?: string
  fontColor?: string
  headerFontSize?: string
  metaFontSize?: string
}

const DownloadCard: React.FC<DownloadCardProps> = ({
  title,
  image,
  mobileImage,
  download,
  metaData,
  fontColor,
  headerFontSize,
  metaFontSize,
}) => {
  return (
    <Link href={download} isExternal>
      <Box w="100%">
        {image && (
          <Box
            width="260px"
            height="337px"
            justifySelf="center"
            position="relative"
          >
            <Image
              src={mobileImage || image}
              alt={title || "File Image"}
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          </Box>
        )}

        <Box pt="20px">
          <Text
            fontFamily="Avenir"
            fontSize={headerFontSize || "19px"}
            fontWeight="800 !important"
            color={fontColor || colors.dark}
            fontStyle="normal"
            lineHeight="24px"
            letterSpacing="0em"
            textAlign="left"
          >
            {title}
          </Text>
          <Text
            fontFamily="Avenir"
            fontSize={metaFontSize || "16px"}
            fontWeight="400"
            color={fontColor || colors.dark}
            mb="32px"
            mt="7px"
          >
            {metaData}
          </Text>
        </Box>
      </Box>
    </Link>
  )
}

export default DownloadCard
