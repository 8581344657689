import { Box, Text } from "@chakra-ui/react"
import { PortableText } from "@utils/sanity"
import Image from "next/image"

import Colors from "@constants/colors"
import { bp } from "@utils/MediaQueries"

export type FeatureItem = {
  image: string
  title: string
}

export interface IFeatureBlockProps {
  title: string
  subtext: any
  subtitle: string
  featureItems: FeatureItem[]
}

const FeatureBlock: React.FC<IFeatureBlockProps> = ({
  title,
  subtext,
  subtitle,
  featureItems,
  ...rest
}) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    {...rest}
  >
    <Box
      width={bp("100%", null, "6col")}
      textAlign="center"
      paddingY={bp("2rem", null, "5rem")}
    >
      <Text
        fontFamily="Avenir"
        fontSize={bp("27.5px", null, "33px")}
        fontWeight="800"
        pb="2rem"
      >
        {title}
      </Text>
      {subtext && (
        <Box
          fontFamily="Avenir"
          fontSize="16px"
          pb="2rem"
          px={bp("1rem", null, null)}
          color={Colors.midAccessible}
        >
          <PortableText blocks={subtext} />
        </Box>
      )}
      <Text
        fontFamily="Avenir"
        fontSize={bp("16px", null, "19px")}
        fontWeight="800"
      >
        {subtitle}
      </Text>
    </Box>
    <Box
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      width={bp("100%", null, "14col")}
      padding="1rem"
    >
      {featureItems?.map((item, idx) => (
        <Box key={idx} mb={bp("2rem", null, "3rem")}>
          <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={bp("336px", null, "5p75col")}
            height={bp("224px", null, "calc(5.75/16 * 100vw * .75)")}
            flexShrink={0}
            bg="#F9F9F9"
            margin="1rem"
          >
            <Image
              src={item.image}
              alt="Featured Image"
              quality={100}
              loading="eager"
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          </Box>
          <Text
            fontFamily="Avenir"
            fontSize={bp("16px", null, "19px")}
            fontWeight="800"
            marginLeft="1rem"
            marginTop={bp("1rem", null, "2rem")}
            pr={bp("1rem", null, null)}
          >
            {item.title}
          </Text>
        </Box>
      ))}
    </Box>
  </Box>
)

export default FeatureBlock
