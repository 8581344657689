import React from "react"
import { Box, BoxProps, Text } from "@chakra-ui/react"
import Image from "next/image"
import Link from "@components/Link"
import { bp, LaptopUpOnly, MobileOnly } from "@utils/MediaQueries"
import { formatUrl } from "@utils/urls"

export interface CardProps extends Omit<BoxProps, "children" | "title"> {
  image: string
  mobileImage?: string
  title: string
  slug: string
  tag?: string
  isExternal?: boolean
}

const Card: React.FC<CardProps> = ({
  image,
  mobileImage,
  title,
  slug,
  tag,
  isExternal,
  ...rest
}) => (
  <Box w="100%" h="100%">
    <Link
      href={slug}
      _hover={{ textDecor: "none" }}
      color="dark"
      external={isExternal}
    >
      <Box
        pos="relative"
        overflow="hidden"
        bg="white"
        cursor="pointer"
        w="100%"
        h="100%"
        {...rest}
      >
        <Box
          bottom={bp("32px", null, "42px")}
          left={bp("40px", null, "0p5col")}
          position="absolute"
          zIndex={1}
        >
          <Text
            fontFamily="Avenir"
            fontSize={bp("19px", null, "27px")}
            fontWeight="800"
            color="white"
          >
            {title}
          </Text>
        </Box>
        <MobileOnly w="100%" h="100%">
          <Image
            src={mobileImage || image}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            loading="lazy"
          />
        </MobileOnly>
        <LaptopUpOnly w="100%" h="100%">
          <Image
            src={image}
            alt={title || "Card Image"}
            layout="fill"
            loading="lazy"
            objectFit="cover"
            objectPosition="center"
          />
        </LaptopUpOnly>
      </Box>
    </Link>
  </Box>
)

export default Card
