import React from "react"
import { CmsContentBaseProps } from "@cms/types"
import MenuAccordion from "@components/Accordion"

interface ComponentMenuAccordionProps extends CmsContentBaseProps {}

const ComponentMenuAccordion: React.FC<ComponentMenuAccordionProps> = ({
  data,
}) => {
  return <MenuAccordion data={data} />
}

export default ComponentMenuAccordion
