import React from "react"
import { Box, BoxProps, Text, css } from "@chakra-ui/react"
import Image from "next/image"
import Link from "@components/Link"
import { bp, LaptopUpOnly, MobileOnly } from "@utils/MediaQueries"

export interface CardProps extends Omit<BoxProps, "children" | "label"> {
  label: string
  image: string
  mobileImage?: string
  link: string
  isExternal?: boolean
}

const Card: React.FC<CardProps> = ({
  label,
  link,
  image,
  mobileImage,
  isExternal,
}) => (
  <Box w="100%" h="100%">
    <Link
      href={link}
      display="flex"
      height="100%"
      color="dark"
      _hover={{ textDecor: "none" }}
      isExternal={isExternal}
    >
      <Box
        pos="relative"
        w="100%"
        overflow="hidden"
        bg="white"
        cursor="pointer"
      >
        <Box
          zIndex={1}
          position="absolute"
          alignItems="flex-end"
          display="flex"
          w="100%"
          h="100%"
          p={bp("32px 40px", null, "52px 50px")}
        >
          <Text
            fontFamily="Avenir"
            fontSize={bp("19px", null, "27px")}
            fontWeight="800"
            color="white"
          >
            {label}
          </Text>
        </Box>
        <MobileOnly
          w="100%"
          h="100%"
          css={css({
            img: {
              width: "100%",
            },
          })}
        >
          <Image
            src={mobileImage || image}
            alt={label || "Card Image"}
            layout="fill"
            loading="lazy"
            objectFit="cover"
            objectPosition="center"
          />
        </MobileOnly>
        <LaptopUpOnly
          w="100%"
          h="100%"
          css={css({
            img: {
              width: "100%",
            },
          })}
        >
          <Image
            src={image}
            alt={label || "Card Image"}
            layout="fill"
            loading="lazy"
            objectFit="cover"
            objectPosition="center"
          />
        </LaptopUpOnly>
      </Box>
    </Link>
  </Box>
)

export default Card
