import React from "react"
import { CmsContentBaseProps } from "../../types"
import ImageGrid from "@components/ImageGrid"
import { urlFor } from "@utils/sanity"

interface ComponentImageGridProps extends CmsContentBaseProps {}

const ComponentImageGrid: React.FC<ComponentImageGridProps> = ({
  title,
  subtitle,
  images,
}) => {
  const formattedImages = images.map(
    ({ image, internalLink, externalLink }) => {
      return {
        url: urlFor(image.asset).url() || "",
        externalLink: externalLink || "",
        internalLink: internalLink || "",
        // TODO: use Alt Text
      }
    }
  )

  return (
    <ImageGrid
      title={title}
      // TODO: subtitle={subtitle}
      images={formattedImages}
    />
  )
}

export default ComponentImageGrid
